import { Fragment } from 'react'
import { NetworkStatus } from '@apollo/client'

import { HomeCard } from '~/src/components/home/HomeCard'
import { usePageMetaContext } from '~/src/contexts'
import { usePostConnection } from '~/src/hooks/post/usePostConnection'
import { nonNullable, isEmpty, buildPostQuery } from '~/src/utils'
import { Button, Loading } from '~/src/components/shared'
import { SectionHeader } from '~/src/components/shared/SectionHeader'
import { useHomePageDataQuery } from '~/src/generated/graphql'

const PAGE_SIZE = 4

export function HomeGrid() {
  const { data: pageData } = usePageMetaContext()
  const categories = pageData?.categories?.map(item => item?.category).filter(nonNullable) ?? []

  const { data } = useHomePageDataQuery()

  const { mainTitle, mainDescription } = data?.homePage || {}

  const { posts, fetchMore, hasNextPage, loading, networkStatus } = usePostConnection({
    where: buildPostQuery({ categories: categories.map(c => c?.categoryId).filter(nonNullable) }),
    start: 0,
    limit: PAGE_SIZE
  })

  const { mobile } = pageData?.displayOption || {}

  if (isEmpty(posts)) return null

  const items = posts.filter(nonNullable)
  const isTotalOdd = posts.length % 2 !== 0

  return (
    <div
      className='container mx-auto'
    >
      {
        mainTitle && (
          <SectionHeader title={mainTitle} description={mainDescription ?? ''} variant="primary" className="my-14 lg:my-24" />
        )
      }
      <div className={`grid grid-cols-${mobile} lg:grid-cols-2 gap-x-4`}>
        {items &&
          items.map((post, index) => {
            const oddIndex = index % 2 !== 0
            const showDummy = isTotalOdd && index === items.length - 1

            return (
              <Fragment key={`${post.id}_${index}`}>
                <div>
                  {oddIndex  && <div className='hidden lg:block lg:h-248px' />}
                  <HomeCard
                    post={post}
                    size='md'
                    color={oddIndex ? 'green' : 'cyan'} />
                  {!oddIndex && <div className='hidden lg:block lg:h-248px' />}
                </div>
                {showDummy && <div />}
              </Fragment>
            )
          })}
        {/* <InfiniteScroll fetchMore={fetchMore} /> */}
      </div>
      {
        loading && networkStatus === NetworkStatus.fetchMore && (
          <div className="py-10">
            <Loading />  
          </div>
        )
      }
      {
        hasNextPage && (
          <div className="text-center py-10 font-bold">
            <Button variant="primary" size="lg" className="font-bold" onClick={fetchMore}>SHOW MORE</Button>
          </div>
        )
      }
    </div>
  )
}
