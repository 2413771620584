import React from 'react'
import AspectRatio from 'react-aspect-ratio'
import { format } from 'date-fns'
import cx from 'classnames'

import { Anchor, Image } from '~/src/components/shared'
import { DEFAULT_DATE_FORMAT } from '~/src/const'
import { PostItemFragment } from '~/src/generated/graphql'

import styles from './HomeCard.module.scss'

interface Props {
  post: PostItemFragment;
  color: 'cyan' | 'green';
  size: 'lg' | 'md';
}

export function HomeCard({ post, color, size }: Props) {
  const { id, coverImage, publishDate, description, title } = post || {}
  return (
    <Anchor
      className='row-span-2'
      href={`/posts/${id}`}>
      <div className={styles.thumbnail}>
        {coverImage && (
          <AspectRatio ratio="16/9">
            <img
              src={coverImage?.formats?.medium?.url}
              className="object-cover w-full h-full"
            />
          </AspectRatio>
        )}
      </div>
      <div
        className={cx(styles.content, styles[size], styles[color])}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>{description}</p>
        <div className={styles.date}>
          {format(new Date(publishDate), DEFAULT_DATE_FORMAT)}
        </div>
      </div>
    </Anchor>
  )
}
