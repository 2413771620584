import Head from 'next/head'
import { BaseLayout } from '~/src/components/shared/layout'
import { PageMetaBanner } from '~/src/components/shared'

import { getTitle } from '~/src/utils'
import { HomeGrid } from '~/src/components/home'
import { PageId } from '~/src/generated/graphql'
import { PageMetaContextProvider } from '~/src/contexts/PageMetaContext'

export default function Home() {
  return (
    <BaseLayout>
      <Head>
        <title>{getTitle('Main')}</title>
      </Head>
      <PageMetaContextProvider pageId={PageId.Main}>
        <PageMetaBanner />
        <HomeGrid />
      </PageMetaContextProvider>
    </BaseLayout>
  )
}
